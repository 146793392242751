var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Flicking",
    {
      ref: "flicking",
      attrs: {
        options: {
          horizontal: true,
          circular: _vm.circular,
          defaultIndex: _vm.defaultIndex,
          align: _vm.align,
          bound: _vm.bound,
          adaptive: _vm.adaptive,
          panelsPerView: _vm.panelsPerView,
          noPanelStyleOverride: _vm.noPanelStyleOverride,
          resizeOnContentsReady: _vm.resizeOnContentsReady,
          deceleration: _vm.deceleration,
          duration: _vm.duration,
          autoResize: _vm.autoResize,
          useFractionalSize: true,
        },
        plugins: [_vm.perspectivePlugin],
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }