var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.grouped
    ? _c(
        "v-list",
        {
          attrs: { "two-line": "", flat: "", expand: "", color: "transparent" },
        },
        _vm._l(_vm.groupedItems, function (group, index) {
          return _c(
            "v-list-group",
            {
              key: index,
              attrs: { value: true, "no-action": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function () {
                      return [
                        group[0] && group[0].key
                          ? _c(
                              "v-list-item-avatar",
                              { attrs: { tile: "", size: "30" } },
                              [
                                _c("v-img", {
                                  staticClass: "my-auto",
                                  attrs: {
                                    src: _vm.$braendz.registrationOfficeCodeFlag(
                                      group[0].key
                                    ),
                                    contain: "",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(
                                _vm._s(
                                  group[0]
                                    ? group[0].shortDescription
                                      ? group[0].shortDescription
                                      : group[0].key
                                    : "Hidden Brands"
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            },
            [
              _vm.selectionMode === "None"
                ? _vm._l(group[1], function (item, index) {
                    return _c("brand-list-item", {
                      key: index,
                      attrs: { value: item, canAddToBookmarks: true },
                    })
                  })
                : [
                    _c(
                      "v-list-item-group",
                      {
                        attrs: { multiple: _vm.selectionMode === "Multiple" },
                        model: {
                          value: _vm.selection,
                          callback: function ($$v) {
                            _vm.selection = $$v
                          },
                          expression: "selection",
                        },
                      },
                      _vm._l(group[1], function (item, index) {
                        return _c("brand-list-item", {
                          key: index,
                          attrs: { value: item, canAddToBookmarks: true },
                        })
                      }),
                      1
                    ),
                  ],
            ],
            2
          )
        }),
        1
      )
    : _c(
        "v-list",
        {
          attrs: { "two-line": "", flat: "", expand: "", color: "transparent" },
        },
        [
          _vm.selectionMode === "None"
            ? _vm._l(_vm.brands, function (item, index) {
                return _c("brand-list-item", {
                  key: index,
                  attrs: { value: item, canAddToBookmarks: true },
                })
              })
            : [
                _c(
                  "v-list-item-group",
                  {
                    attrs: { multiple: _vm.selectionMode === "Multiple" },
                    model: {
                      value: _vm.selection,
                      callback: function ($$v) {
                        _vm.selection = $$v
                      },
                      expression: "selection",
                    },
                  },
                  _vm._l(_vm.brands, function (item, index) {
                    return _c("brand-list-item", {
                      key: index,
                      attrs: { value: item, canAddToBookmarks: true },
                    })
                  }),
                  1
                ),
              ],
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }