var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-main", { ref: "main", staticClass: "bg-light" }, [
    _c(
      "div",
      { staticClass: "d-flex", staticStyle: { "min-height": "100%" } },
      [
        _vm.$store.getters.hasFeature("BRAND_MONITOR")
          ? _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _c(
                        "div",
                        { staticClass: "section-title decorative-border-left" },
                        [
                          _c("h1", [
                            _vm._v(_vm._s(_vm.$t("monitorBrand.header.title"))),
                          ]),
                          _c("h3", [
                            _vm._v(
                              _vm._s(_vm.$t("monitorBrand.header.description"))
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-stepper",
                          {
                            attrs: { flat: "", "non-linear": "" },
                            model: {
                              value: _vm.step,
                              callback: function ($$v) {
                                _vm.step = $$v
                              },
                              expression: "step",
                            },
                          },
                          [
                            _vm.$vuetify.breakpoint.mdAndUp
                              ? _c(
                                  "v-stepper-header",
                                  { staticClass: "elevation-0" },
                                  [
                                    _c(
                                      "v-stepper-step",
                                      {
                                        attrs: {
                                          editable: _vm.completedStep >= 0,
                                          "complete-icon": "fa-light fa-check",
                                          "edit-icon": "fa-light fa-check",
                                          step: 1,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpToStep(1)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "monitorBrand.wizard.selectBrand"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-stepper-step",
                                      {
                                        attrs: {
                                          editable: _vm.completedStep >= 1,
                                          "complete-icon": "fa-light fa-check",
                                          "edit-icon": "fa-light fa-check",
                                          step: 2,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpToStep(2)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "monitorBrand.wizard.assess"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-stepper-step",
                                      {
                                        attrs: {
                                          editable: _vm.completedStep >= 2,
                                          "complete-icon": "fa-light fa-check",
                                          "edit-icon": "fa-light fa-check",
                                          step: 3,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpToStep(3)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "monitorBrand.wizard.summary"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-stepper-step",
                                      {
                                        attrs: {
                                          editable: _vm.completedStep >= 2,
                                          "complete-icon": "fa-light fa-check",
                                          "edit-icon": "fa-light fa-check",
                                          step: 4,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpToStep(4)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "monitorBrand.wizard.automation"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-stepper-items",
                              [
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: 1 } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "mb-2",
                                        attrs: {
                                          column: "",
                                          "align-center": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: {
                                              elevation: "3",
                                              rounded: "",
                                              width: "100%",
                                            },
                                          },
                                          [
                                            _c("v-card-title", [
                                              _c("span", [
                                                _vm._v("Ihre Marke"),
                                              ]),
                                            ]),
                                            _c("v-card-subtitle", [
                                              _c("span", [
                                                _vm._v(
                                                  "Wählen Sie Ihre Marke aus, die Sie überwachen möchten."
                                                ),
                                              ]),
                                            ]),
                                            _c("v-divider", {
                                              staticClass: "mx-3",
                                            }),
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass:
                                                  "d-flex flex-column",
                                              },
                                              [
                                                _c("brand-picker", {
                                                  model: {
                                                    value: _vm.selectedBrand,
                                                    callback: function ($$v) {
                                                      _vm.selectedBrand = $$v
                                                    },
                                                    expression: "selectedBrand",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-actions",
                                              {
                                                staticClass:
                                                  "mt-10 d-flex justify-end",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      light: "",
                                                      color: "tertiary",
                                                      disabled:
                                                        !_vm.selectedBrand,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setStep(2)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "monitorBrand.wizard.buttonNext"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          right: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-chevron-right"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: 2 } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "mb-2",
                                        attrs: {
                                          column: "",
                                          "align-center": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: {
                                              elevation: "3",
                                              rounded: "",
                                              width: "100%",
                                              loading:
                                                _vm.brandQueryResult.isBusy,
                                            },
                                          },
                                          [
                                            _c("v-card-title", [
                                              _c("span", [_vm._v("Bewertung")]),
                                            ]),
                                            _c("v-card-subtitle", [
                                              _c("span", [
                                                _vm._v(
                                                  "Beurteilen Sie die gefundenen Konflikte."
                                                ),
                                              ]),
                                            ]),
                                            _c("v-divider", {
                                              staticClass: "mx-3",
                                            }),
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "v-toolbar",
                                                  {
                                                    staticClass: "px-0",
                                                    attrs: {
                                                      dense: "",
                                                      light: "",
                                                      flat: "",
                                                      "max-height": "48",
                                                      color: "transparent",
                                                    },
                                                  },
                                                  [
                                                    _c("v-spacer"),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.showBrandQueryConfigurationPopup = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "fa-light fa-gears"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            !_vm.brandQueryResult.isBusy &&
                                            _vm.brandQueryResult.object &&
                                            _vm.brandQueryResult.object.items &&
                                            _vm.brandQueryResult.object &&
                                            _vm.brandQueryResult.object.items
                                              .length > 0
                                              ? _c(
                                                  "v-card-text",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column",
                                                  },
                                                  [
                                                    _vm.currentAssessBrand &&
                                                    _vm.currentAssessBrand.score
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-row justify-center",
                                                          },
                                                          [
                                                            _vm
                                                              .currentAssessBrand
                                                              .score
                                                              .nameSimilarityScore
                                                              ? _c(
                                                                  "Label",
                                                                  {
                                                                    attrs: {
                                                                      text: "Markenname",
                                                                      location:
                                                                        "bottom",
                                                                      align:
                                                                        "center",
                                                                      offsetY: 12,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "radial-bar",
                                                                      {
                                                                        attrs: {
                                                                          width: 100,
                                                                          height: 100,
                                                                          label:
                                                                            "Markenname",
                                                                          value:
                                                                            _vm
                                                                              .currentAssessBrand
                                                                              .score
                                                                              .nameSimilarityScore *
                                                                            100,
                                                                          showValue: true,
                                                                          startAngle:
                                                                            -135,
                                                                          endAngle: 135,
                                                                          valueColors:
                                                                            _vm.riskColors,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm
                                                              .currentAssessBrand
                                                              .score
                                                              .logoSimilarityScore
                                                              ? _c(
                                                                  "Label",
                                                                  {
                                                                    attrs: {
                                                                      text: "Logo",
                                                                      location:
                                                                        "bottom",
                                                                      align:
                                                                        "center",
                                                                      offsetY: 12,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "radial-bar",
                                                                      {
                                                                        attrs: {
                                                                          width: 100,
                                                                          height: 100,
                                                                          label:
                                                                            "Logo",
                                                                          value:
                                                                            _vm
                                                                              .currentAssessBrand
                                                                              .score
                                                                              .logoSimilarityScore *
                                                                            100,
                                                                          showValue: true,
                                                                          startAngle:
                                                                            -135,
                                                                          endAngle: 135,
                                                                          valueColors:
                                                                            _vm.riskColors,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm
                                                              .currentAssessBrand
                                                              .score
                                                              .goodsServicesSimilarityScore
                                                              ? _c(
                                                                  "Label",
                                                                  {
                                                                    attrs: {
                                                                      text: "Tätigkeiten",
                                                                      location:
                                                                        "bottom",
                                                                      align:
                                                                        "center",
                                                                      offsetY: 12,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "radial-bar",
                                                                      {
                                                                        attrs: {
                                                                          width: 100,
                                                                          height: 100,
                                                                          label:
                                                                            "Tätigkeiten",
                                                                          value:
                                                                            _vm
                                                                              .currentAssessBrand
                                                                              .score
                                                                              .goodsServicesSimilarityScore *
                                                                            100,
                                                                          showValue: true,
                                                                          startAngle:
                                                                            -135,
                                                                          endAngle: 135,
                                                                          valueColors:
                                                                            _vm.riskColors,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm
                                                              .currentAssessBrand
                                                              .score
                                                              .niceClassesCoverage
                                                              ? _c(
                                                                  "Label",
                                                                  {
                                                                    attrs: {
                                                                      text: "Klassifizierung",
                                                                      location:
                                                                        "bottom",
                                                                      align:
                                                                        "center",
                                                                      offsetY: 12,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "radial-bar",
                                                                      {
                                                                        attrs: {
                                                                          width: 100,
                                                                          height: 100,
                                                                          label:
                                                                            "Klassifizierung",
                                                                          value:
                                                                            _vm
                                                                              .currentAssessBrand
                                                                              .score
                                                                              .niceClassesCoverage *
                                                                            100,
                                                                          showValue: true,
                                                                          startAngle:
                                                                            -135,
                                                                          endAngle: 135,
                                                                          valueColors:
                                                                            _vm.riskColors,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.brandQueryResult
                                                      .object &&
                                                    _vm.brandQueryResult.object
                                                      .items
                                                      ? _c("Carousel3dSlider", {
                                                          key: _vm.brandQueryResultKey,
                                                          ref: "assessmentSlider",
                                                          attrs: {
                                                            loop: true,
                                                            width: 400,
                                                            height: 700,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              _vm._l(
                                                                _vm
                                                                  .brandQueryResult
                                                                  .object.items,
                                                                function (
                                                                  brand,
                                                                  index
                                                                ) {
                                                                  return {
                                                                    key:
                                                                      "slide-" +
                                                                      index,
                                                                    fn: function () {
                                                                      return [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            key:
                                                                              brand.indexItem &&
                                                                              brand
                                                                                .indexItem
                                                                                .id
                                                                                ? brand
                                                                                    .indexItem
                                                                                    .id
                                                                                : index,
                                                                            staticClass:
                                                                              "pa-2",
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "100%",
                                                                                height:
                                                                                  "100%",
                                                                                "box-sizing":
                                                                                  "border-box",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-badge",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "100%",
                                                                                    height:
                                                                                      "100%",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    value:
                                                                                      _vm.assessmentStatus(
                                                                                        brand
                                                                                      ) !==
                                                                                      null,
                                                                                    left: "",
                                                                                    bordered:
                                                                                      "",
                                                                                    overlap:
                                                                                      "",
                                                                                    icon:
                                                                                      _vm.assessmentStatus(
                                                                                        brand
                                                                                      ) ===
                                                                                      "Excluded"
                                                                                        ? "fa-light fa-x"
                                                                                        : _vm.assessmentStatus(
                                                                                            brand
                                                                                          ) ===
                                                                                          "Ignored"
                                                                                        ? "fa-light fa-check"
                                                                                        : _vm.assessmentStatus(
                                                                                            brand
                                                                                          ) ===
                                                                                          "Monitored"
                                                                                        ? "fa-light fa-chevron-right"
                                                                                        : undefined,
                                                                                    color:
                                                                                      _vm.assessmentStatus(
                                                                                        brand
                                                                                      ) ===
                                                                                      "Excluded"
                                                                                        ? "gray"
                                                                                        : _vm.assessmentStatus(
                                                                                            brand
                                                                                          ) ===
                                                                                          "Ignored"
                                                                                        ? "green darken-3"
                                                                                        : _vm.assessmentStatus(
                                                                                            brand
                                                                                          ) ===
                                                                                          "Monitored"
                                                                                        ? "orange lighten-1"
                                                                                        : undefined,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "VerticalBrandTile",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                        height:
                                                                                          "calc(100% - 11px)",
                                                                                        brand:
                                                                                          brand,
                                                                                        showGoodsServices: true,
                                                                                        canAddToBookmarks: true,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    },
                                                                    proxy: true,
                                                                  }
                                                                }
                                                              ),
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.currentAssessBrandIndex,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.currentAssessBrandIndex =
                                                                $$v
                                                            },
                                                            expression:
                                                              "currentAssessBrandIndex",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm.currentAssessBrand
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-row justify-center pt-2",
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.assessmentStatus(
                                                                      _vm.currentAssessBrand
                                                                    ) ===
                                                                      "Ignored" ||
                                                                    !_vm.currentAssessBrand ||
                                                                    !_vm
                                                                      .currentAssessBrand
                                                                      .indexItem ||
                                                                    !_vm
                                                                      .currentAssessBrand
                                                                      .indexItem
                                                                      .id,
                                                                  dark: !(
                                                                    _vm.assessmentStatus(
                                                                      _vm.currentAssessBrand
                                                                    ) ===
                                                                      "Ignored" ||
                                                                    !_vm.currentAssessBrand ||
                                                                    !_vm
                                                                      .currentAssessBrand
                                                                      .indexItem ||
                                                                    !_vm
                                                                      .currentAssessBrand
                                                                      .indexItem
                                                                      .id
                                                                  ),
                                                                  fab: "",
                                                                  color:
                                                                    "green darken-3",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function () {
                                                                      _vm.ignoreBrand(
                                                                        _vm.currentAssessBrand
                                                                      )
                                                                      _vm.assessNext()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "fa-light fa-check"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.assessmentStatus(
                                                                      _vm.currentAssessBrand
                                                                    ) ===
                                                                      "Monitored" ||
                                                                    !_vm.currentAssessBrand ||
                                                                    !_vm
                                                                      .currentAssessBrand
                                                                      .indexItem ||
                                                                    !_vm
                                                                      .currentAssessBrand
                                                                      .indexItem
                                                                      .id,
                                                                  dark: !(
                                                                    _vm.assessmentStatus(
                                                                      _vm.currentAssessBrand
                                                                    ) ===
                                                                      "Monitored" ||
                                                                    !_vm.currentAssessBrand ||
                                                                    !_vm
                                                                      .currentAssessBrand
                                                                      .indexItem ||
                                                                    !_vm
                                                                      .currentAssessBrand
                                                                      .indexItem
                                                                      .id
                                                                  ),
                                                                  fab: "",
                                                                  color:
                                                                    "orange lighten-1",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function () {
                                                                      _vm.monitorBrand(
                                                                        _vm.currentAssessBrand
                                                                      )
                                                                      _vm.assessNext()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "fa-light fa-chevron-right"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm.brandQueryResult.isBusy
                                              ? _c(
                                                  "v-card-text",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column justify-center align-center",
                                                  },
                                                  [_vm._v(" Loading... ")]
                                                )
                                              : [
                                                  _c(
                                                    "v-card-text",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column align-center",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row",
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                color:
                                                                  "tertiary",
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-light fa-thumbs-up"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "tertiary--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Es wurden keine Konflikte gefunden."
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            text: "",
                                                            light: "",
                                                            color: "tertiary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.showBrandQueryConfigurationPopup = true
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Suche anpassen "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                            _c(
                                              "v-card-actions",
                                              {
                                                staticClass:
                                                  "mt-10 d-flex justify-space-between",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      light: "",
                                                      color: "tertiary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setStep(1)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-chevron-left"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "monitorBrand.wizard.buttonBack"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      light: "",
                                                      color: "tertiary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setStep(3)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "monitorBrand.wizard.buttonNext"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          right: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-chevron-right"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: 3 } },
                                  [
                                    _c(
                                      "v-container",
                                      {
                                        staticClass: "pa-0 mb-2",
                                        attrs: { fluid: "" },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  xl: "3",
                                                  lg: "4",
                                                  md: "6",
                                                  sm: "12",
                                                  cols: "12",
                                                },
                                              },
                                              [
                                                _c("VerticalBrandTile", {
                                                  staticClass:
                                                    "mx-auto d-flex flex-column rounded-lg fill-height",
                                                  attrs: {
                                                    brand: _vm.selectedBrand,
                                                    showGoodsServices: true,
                                                    canAddToBookmarks: true,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "pa-0",
                                                attrs: {
                                                  xl: "9",
                                                  lg: "8",
                                                  md: "6",
                                                  sm: "12",
                                                  cols: "12",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass:
                                                      "fill-height pa-0 ma-0",
                                                  },
                                                  [
                                                    _vm.selectedBrand &&
                                                    _vm.selectedBrand
                                                      .indexItem &&
                                                    _vm.selectedBrand.indexItem
                                                      .name
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              xl: "4",
                                                              lg: "6",
                                                              md: "12",
                                                              cols: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "dashboard-card",
                                                              {
                                                                attrs: {
                                                                  title: _vm.$t(
                                                                    "monitorBrand.brandNameSimilarities.title"
                                                                  ),
                                                                  subtitle:
                                                                    _vm.$t(
                                                                      "monitorBrand.brandNameSimilarities.subtitle"
                                                                    ),
                                                                  nodata:
                                                                    !_vm
                                                                      .brandQueryResult
                                                                      .object ||
                                                                    !_vm
                                                                      .brandQueryResult
                                                                      .object
                                                                      .score,
                                                                  loading:
                                                                    _vm
                                                                      .brandQueryResult
                                                                      .isBusy,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      _vm
                                                                        .brandQueryResult
                                                                        .object &&
                                                                      _vm
                                                                        .brandQueryResult
                                                                        .object
                                                                        .score
                                                                        ? {
                                                                            key: "content",
                                                                            fn: function () {
                                                                              return [
                                                                                _c(
                                                                                  "v-card-text",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pt-1",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "d-flex flex-row justify-space-around",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "Label",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                text: _vm.$t(
                                                                                                  "monitorBrand.dashboards.risk"
                                                                                                ),
                                                                                                location:
                                                                                                  "bottom",
                                                                                                align:
                                                                                                  "center",
                                                                                                offsetY: 12,
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "radial-bar",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    width: 130,
                                                                                                    height: 130,
                                                                                                    label:
                                                                                                      _vm.$t(
                                                                                                        "monitorBrand.dashboards.risk"
                                                                                                      ),
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .nameMaxSimilarityScore
                                                                                                        ? _vm
                                                                                                            .brandQueryResult
                                                                                                            .object
                                                                                                            .score
                                                                                                            .nameMaxSimilarityScore *
                                                                                                          100
                                                                                                        : 0,
                                                                                                    showValue: true,
                                                                                                    startAngle:
                                                                                                      -135,
                                                                                                    endAngle: 135,
                                                                                                    valueColors:
                                                                                                      _vm.riskColors,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _c(
                                                                                          "Counter",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                titleSingular:
                                                                                                  _vm.$t(
                                                                                                    "monitorBrand.dashboards.conflict"
                                                                                                  ),
                                                                                                titlePlural:
                                                                                                  _vm.$t(
                                                                                                    "monitorBrand.dashboards.conflicts"
                                                                                                  ),
                                                                                                criticalNumber: 1,
                                                                                                count:
                                                                                                  _vm
                                                                                                    .brandQueryResult
                                                                                                    .object
                                                                                                    .score
                                                                                                    .nameExactMatchCount
                                                                                                    ? _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .nameExactMatchCount
                                                                                                    : 0,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-divider",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mx-3",
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "v-card-text",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fill-height",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-layout",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            column:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "d-flex flex-row justify-space-between",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "body-1",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "monitorBrand.brandNameSimilarities.exactNameCount"
                                                                                                      )
                                                                                                    )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                class:
                                                                                                  {
                                                                                                    "body-1 font-weight-bold": true,
                                                                                                    "color-ok":
                                                                                                      !_vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .nameExactMatchCount ||
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .nameExactMatchCount ===
                                                                                                        0,
                                                                                                    "color-risky":
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .nameExactMatchCount &&
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .nameExactMatchCount >
                                                                                                        0,
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .nameExactMatchCount
                                                                                                        ? _vm
                                                                                                            .brandQueryResult
                                                                                                            .object
                                                                                                            .score
                                                                                                            .nameExactMatchCount
                                                                                                        : 0
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "d-flex flex-row justify-space-between",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "body-1",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "monitorBrand.brandNameSimilarities.similarNameCount"
                                                                                                      )
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                class:
                                                                                                  {
                                                                                                    "body-1 font-weight-bold": true,
                                                                                                    "color-ok":
                                                                                                      !_vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .nameSimilarMatchCount ||
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .nameSimilarMatchCount ===
                                                                                                        0,
                                                                                                    "color-risky":
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .nameSimilarMatchCount &&
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .nameSimilarMatchCount >
                                                                                                        0,
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .nameSimilarMatchCount
                                                                                                        ? _vm
                                                                                                            .brandQueryResult
                                                                                                            .object
                                                                                                            .score
                                                                                                            .nameSimilarMatchCount
                                                                                                        : 0
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                            proxy: true,
                                                                          }
                                                                        : null,
                                                                      {
                                                                        key: "content-loading",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "monitorBrand.dashboards.loading"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "content-nodata",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "monitorBrand.dashboards.nodata"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "actions",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    text: "",
                                                                                    disabled:
                                                                                      !_vm
                                                                                        .brandQueryResult
                                                                                        .object,
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    _vm.showConflicts,
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        left: "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fa-light fa-grid-2"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "monitorBrand.dashboards.buttonDetails"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _c(
                                                                              "thumbs-buttons",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    tooltip:
                                                                                      "bottom",
                                                                                    small:
                                                                                      "",
                                                                                    context:
                                                                                      "Monitor Brand Name Similarities",
                                                                                    disabled:
                                                                                      !_vm
                                                                                        .brandQueryResult
                                                                                        .object,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.selectedBrand &&
                                                    _vm.selectedBrand
                                                      .indexItem &&
                                                    _vm.selectedBrand.indexItem
                                                      .brandLogoUrl
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              xl: "4",
                                                              lg: "6",
                                                              md: "12",
                                                              cols: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "dashboard-card",
                                                              {
                                                                attrs: {
                                                                  title: _vm.$t(
                                                                    "monitorBrand.logoSimilarities.title"
                                                                  ),
                                                                  subtitle:
                                                                    _vm.$t(
                                                                      "monitorBrand.logoSimilarities.subtitle"
                                                                    ),
                                                                  nodata:
                                                                    !_vm
                                                                      .brandQueryResult
                                                                      .object ||
                                                                    !_vm
                                                                      .brandQueryResult
                                                                      .object
                                                                      .score,
                                                                  loading:
                                                                    _vm
                                                                      .brandQueryResult
                                                                      .isBusy,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      _vm
                                                                        .brandQueryResult
                                                                        .object &&
                                                                      _vm
                                                                        .brandQueryResult
                                                                        .object
                                                                        .score
                                                                        ? {
                                                                            key: "content",
                                                                            fn: function () {
                                                                              return [
                                                                                _c(
                                                                                  "v-card-text",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pt-1",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "d-flex flex-row justify-space-around",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "Label",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                text: _vm.$t(
                                                                                                  "monitorBrand.dashboards.risk"
                                                                                                ),
                                                                                                location:
                                                                                                  "bottom",
                                                                                                align:
                                                                                                  "center",
                                                                                                offsetY: 12,
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "radial-bar",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    width: 130,
                                                                                                    height: 130,
                                                                                                    label:
                                                                                                      _vm.$t(
                                                                                                        "monitorBrand.dashboards.risk"
                                                                                                      ),
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .logoMaxSimilarityScore
                                                                                                        ? _vm
                                                                                                            .brandQueryResult
                                                                                                            .object
                                                                                                            .score
                                                                                                            .logoMaxSimilarityScore *
                                                                                                          100
                                                                                                        : 0,
                                                                                                    showValue: true,
                                                                                                    startAngle:
                                                                                                      -135,
                                                                                                    endAngle: 135,
                                                                                                    valueColors:
                                                                                                      _vm.riskColors,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _c(
                                                                                          "Counter",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                titleSingular:
                                                                                                  _vm.$t(
                                                                                                    "monitorBrand.dashboards.conflict"
                                                                                                  ),
                                                                                                titlePlural:
                                                                                                  _vm.$t(
                                                                                                    "monitorBrand.dashboards.conflicts"
                                                                                                  ),
                                                                                                criticalNumber: 1,
                                                                                                count:
                                                                                                  _vm
                                                                                                    .brandQueryResult
                                                                                                    .object
                                                                                                    .score
                                                                                                    .logoExactMatchCount
                                                                                                    ? _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .logoExactMatchCount
                                                                                                    : 0,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-divider",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mx-3",
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "v-card-text",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fill-height",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-layout",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            column:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "d-flex flex-row justify-space-between",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "body-1",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "monitorBrand.logoSimilarities.exactLogoCount"
                                                                                                      )
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                class:
                                                                                                  {
                                                                                                    "body-1 font-weight-bold": true,
                                                                                                    "color-ok":
                                                                                                      !_vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .logoExactMatchCount ||
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .logoExactMatchCount ===
                                                                                                        0,
                                                                                                    "color-risky":
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .logoExactMatchCount &&
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .logoExactMatchCount >
                                                                                                        0,
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .logoExactMatchCount
                                                                                                        ? _vm
                                                                                                            .brandQueryResult
                                                                                                            .object
                                                                                                            .score
                                                                                                            .logoExactMatchCount
                                                                                                        : 0
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "d-flex flex-row justify-space-between",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "body-1",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "monitorBrand.logoSimilarities.similarLogoCount"
                                                                                                      )
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                class:
                                                                                                  {
                                                                                                    "body-1 font-weight-bold": true,
                                                                                                    "color-ok":
                                                                                                      !_vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .logoSimilarMatchCount ||
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .logoSimilarMatchCount ===
                                                                                                        0,
                                                                                                    "color-risky":
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .logoSimilarMatchCount &&
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .logoSimilarMatchCount >
                                                                                                        0,
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .brandQueryResult
                                                                                                        .object
                                                                                                        .score
                                                                                                        .logoSimilarMatchCount
                                                                                                        ? _vm
                                                                                                            .brandQueryResult
                                                                                                            .object
                                                                                                            .score
                                                                                                            .logoSimilarMatchCount
                                                                                                        : 0
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                            proxy: true,
                                                                          }
                                                                        : null,
                                                                      {
                                                                        key: "content-loading",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "monitorBrand.dashboards.loading"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "content-nodata",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "monitorBrand.dashboards.nodata"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "actions",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    text: "",
                                                                                    disabled:
                                                                                      !_vm
                                                                                        .brandQueryResult
                                                                                        .object,
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    _vm.showConflicts,
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        left: "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fa-light fa-grid-2"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "monitorBrand.dashboards.buttonDetails"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _c(
                                                                              "thumbs-buttons",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    tooltip:
                                                                                      "bottom",
                                                                                    small:
                                                                                      "",
                                                                                    context:
                                                                                      "Monitor Brand Logo Similarities",
                                                                                    disabled:
                                                                                      !_vm
                                                                                        .brandQueryResult
                                                                                        .object,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          xl: "4",
                                                          lg: "6",
                                                          md: "12",
                                                          cols: "12",
                                                        },
                                                      },
                                                      [
                                                        _c("dashboard-card", {
                                                          attrs: {
                                                            title: _vm.$t(
                                                              "monitorBrand.classificationSimilarities.title"
                                                            ),
                                                            subtitle: _vm.$t(
                                                              "monitorBrand.classificationSimilarities.subtitle"
                                                            ),
                                                            nodata:
                                                              !_vm
                                                                .brandQueryResult
                                                                .object ||
                                                              !_vm
                                                                .brandQueryResult
                                                                .object.score,
                                                            loading:
                                                              _vm
                                                                .brandQueryResult
                                                                .isBusy,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              _vm
                                                                .brandQueryResult
                                                                .object &&
                                                              _vm
                                                                .brandQueryResult
                                                                .object.score
                                                                ? {
                                                                    key: "content",
                                                                    fn: function () {
                                                                      return [
                                                                        _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "pt-1",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex flex-row justify-space-around",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "Label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        text: _vm.$t(
                                                                                          "monitorBrand.dashboards.risk"
                                                                                        ),
                                                                                        location:
                                                                                          "bottom",
                                                                                        align:
                                                                                          "center",
                                                                                        offsetY: 12,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "radial-bar",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            width: 130,
                                                                                            height: 130,
                                                                                            label:
                                                                                              _vm.$t(
                                                                                                "monitorBrand.dashboards.risk"
                                                                                              ),
                                                                                            value:
                                                                                              _vm
                                                                                                .brandQueryResult
                                                                                                .object
                                                                                                .score
                                                                                                .goodsServicesMaxSimilarityScore
                                                                                                ? _vm
                                                                                                    .brandQueryResult
                                                                                                    .object
                                                                                                    .score
                                                                                                    .goodsServicesMaxSimilarityScore *
                                                                                                  100
                                                                                                : 0,
                                                                                            showValue: true,
                                                                                            startAngle:
                                                                                              -135,
                                                                                            endAngle: 135,
                                                                                            valueColors:
                                                                                              _vm.riskColors,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "Counter",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        titleSingular:
                                                                                          _vm.$t(
                                                                                            "monitorBrand.dashboards.conflict"
                                                                                          ),
                                                                                        titlePlural:
                                                                                          _vm.$t(
                                                                                            "monitorBrand.dashboards.conflicts"
                                                                                          ),
                                                                                        criticalNumber: 1,
                                                                                        count:
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .goodsServicesSimilarCount
                                                                                            ? _vm
                                                                                                .brandQueryResult
                                                                                                .object
                                                                                                .score
                                                                                                .goodsServicesSimilarCount
                                                                                            : 0,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-divider",
                                                                          {
                                                                            staticClass:
                                                                              "mx-3",
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "v-card-text",
                                                                          [
                                                                            _c(
                                                                              "v-layout",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    column:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex flex-row justify-space-between",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "body-1",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "monitorBrand.classificationSimilarities.similarGoodsServicesCount"
                                                                                              )
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        class:
                                                                                          {
                                                                                            "body-1 font-weight-bold": true,
                                                                                            "color-ok":
                                                                                              !_vm
                                                                                                .brandQueryResult
                                                                                                .object
                                                                                                .score
                                                                                                .goodsServicesSimilarCount ||
                                                                                              _vm
                                                                                                .brandQueryResult
                                                                                                .object
                                                                                                .score
                                                                                                .goodsServicesSimilarCount ===
                                                                                                0,
                                                                                            "color-risky":
                                                                                              _vm
                                                                                                .brandQueryResult
                                                                                                .object
                                                                                                .score
                                                                                                .goodsServicesSimilarCount &&
                                                                                              _vm
                                                                                                .brandQueryResult
                                                                                                .object
                                                                                                .score
                                                                                                .goodsServicesSimilarCount >
                                                                                                0,
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              _vm
                                                                                                .brandQueryResult
                                                                                                .object
                                                                                                .score
                                                                                                .goodsServicesSimilarCount
                                                                                                ? _vm
                                                                                                    .brandQueryResult
                                                                                                    .object
                                                                                                    .score
                                                                                                    .goodsServicesSimilarCount
                                                                                                : 0
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex flex-row justify-space-between",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "body-1",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "monitorBrand.classificationSimilarities.equalNiceClassesCount"
                                                                                              )
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        class:
                                                                                          {
                                                                                            "body-1 font-weight-bold": true,
                                                                                            "color-ok":
                                                                                              !_vm
                                                                                                .brandQueryResult
                                                                                                .object
                                                                                                .score
                                                                                                .niceClassesOverlapCount ||
                                                                                              _vm
                                                                                                .brandQueryResult
                                                                                                .object
                                                                                                .score
                                                                                                .niceClassesOverlapCount ===
                                                                                                0,
                                                                                            "color-risky":
                                                                                              _vm
                                                                                                .brandQueryResult
                                                                                                .object
                                                                                                .score
                                                                                                .niceClassesOverlapCount &&
                                                                                              _vm
                                                                                                .brandQueryResult
                                                                                                .object
                                                                                                .score
                                                                                                .niceClassesOverlapCount >
                                                                                                0,
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              _vm
                                                                                                .brandQueryResult
                                                                                                .object
                                                                                                .score
                                                                                                .niceClassesOverlapCount
                                                                                                ? _vm
                                                                                                    .brandQueryResult
                                                                                                    .object
                                                                                                    .score
                                                                                                    .niceClassesOverlapCount
                                                                                                : 0
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    },
                                                                    proxy: true,
                                                                  }
                                                                : null,
                                                              {
                                                                key: "content-loading",
                                                                fn: function () {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "monitorBrand.dashboards.loading"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                              {
                                                                key: "content-nodata",
                                                                fn: function () {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "monitorBrand.dashboards.nodata"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                              {
                                                                key: "actions",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          disabled:
                                                                            !_vm
                                                                              .brandQueryResult
                                                                              .object,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.showConflicts,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "fa-light fa-grid-2"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "monitorBrand.dashboards.buttonDetails"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-spacer"
                                                                    ),
                                                                    _c(
                                                                      "thumbs-buttons",
                                                                      {
                                                                        attrs: {
                                                                          tooltip:
                                                                            "bottom",
                                                                          small:
                                                                            "",
                                                                          context:
                                                                            "Monitor Brand Classification Similarities",
                                                                          disabled:
                                                                            !_vm
                                                                              .brandQueryResult
                                                                              .object,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          xl: "4",
                                                          lg: "6",
                                                          md: "12",
                                                          cols: "12",
                                                        },
                                                      },
                                                      [
                                                        _c("dashboard-card", {
                                                          attrs: {
                                                            title: "Bewertung",
                                                            subtitle:
                                                              "Akzeptierte Risiken",
                                                            loading:
                                                              _vm
                                                                .brandQueryResult
                                                                .isBusy,
                                                            nodata:
                                                              !_vm
                                                                .brandQueryResult
                                                                .object,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "content",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-card-text",
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex flex-row justify-space-around",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "Counter",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    titleSingular:
                                                                                      "Akzeptierte Marke",
                                                                                    titlePlural:
                                                                                      "Akzeptierte Marken",
                                                                                    count:
                                                                                      _vm
                                                                                        .ignoredBrands
                                                                                        .length,
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "Counter",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    titleSingular:
                                                                                      "Offenes Risiko",
                                                                                    titlePlural:
                                                                                      "Offene Risiken",
                                                                                    count:
                                                                                      _vm
                                                                                        .brandQueryResult
                                                                                        .object &&
                                                                                      _vm
                                                                                        .brandQueryResult
                                                                                        .object
                                                                                        .items
                                                                                        ? _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .items
                                                                                            .length -
                                                                                          _vm
                                                                                            .ignoredBrands
                                                                                            .length
                                                                                        : 0,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                              {
                                                                key: "actions",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.setStep(
                                                                                2
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "fa-light fa-rectangle-vertical-history"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " Bewerten "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2451073339
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row justify-space-between",
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          text: "",
                                                          light: "",
                                                          color: "tertiary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setStep(
                                                              2
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              left: "",
                                                              color: "primary",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fa-light fa-chevron-left"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "monitorBrand.wizard.buttonBack"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          text: "",
                                                          light: "",
                                                          color: "tertiary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setStep(
                                                              4
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "monitorBrand.wizard.buttonNext"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              right: "",
                                                              color: "primary",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fa-light fa-chevron-right"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.$vuetify.breakpoint.lgAndUp &&
                                        _vm.conflictedBrands
                                          ? _c(
                                              "v-row",
                                              { attrs: { id: "conflicts" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "section-title decorative-border-left",
                                                      },
                                                      [
                                                        _c("h2", [
                                                          _vm._v(
                                                            "Aktuelle Konflikte"
                                                          ),
                                                        ]),
                                                        _c("h3", [
                                                          _vm._v(
                                                            "Folgende Marken erfordern Ihre Aufmerksamkeit:"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-toolbar",
                                                      {
                                                        staticClass: "px-0",
                                                        attrs: {
                                                          dense: "",
                                                          light: "",
                                                          flat: "",
                                                          "max-height": "48",
                                                        },
                                                      },
                                                      [
                                                        _vm.$vuetify.breakpoint
                                                          .lgAndUp
                                                          ? _c("v-spacer")
                                                          : _vm._e(),
                                                        _vm.$vuetify.breakpoint
                                                          .mdAndUp
                                                          ? _c(
                                                              "v-btn-toggle",
                                                              {
                                                                attrs: {
                                                                  mandatory: "",
                                                                  dense: "",
                                                                  group: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.activeConflictedBrandsView,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.activeConflictedBrandsView =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "activeConflictedBrandsView",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "carousel-view",
                                                                      text: "",
                                                                      disabled:
                                                                        _vm
                                                                          .conflictedBrands
                                                                          .length ===
                                                                        0,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "fa-light fa-rectangle-vertical-history"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "vertical-view",
                                                                      text: "",
                                                                      disabled:
                                                                        _vm
                                                                          .conflictedBrands
                                                                          .length ===
                                                                        0,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "fa-light fa-grid"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.conflictedBrands
                                                      .length > 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column pb-2",
                                                          },
                                                          [
                                                            _c(
                                                              "Carousel3dSlider",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.activeConflictedBrandsView ===
                                                                      "carousel-view",
                                                                    expression:
                                                                      "activeConflictedBrandsView === 'carousel-view'",
                                                                  },
                                                                ],
                                                                key: _vm.brandQueryResultKey,
                                                                attrs: {
                                                                  loop: true,
                                                                  width: 400,
                                                                  height: 800,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      _vm._l(
                                                                        _vm.conflictedBrands,
                                                                        function (
                                                                          brand,
                                                                          index
                                                                        ) {
                                                                          return {
                                                                            key:
                                                                              "slide-" +
                                                                              index,
                                                                            fn: function () {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    key: index,
                                                                                    staticClass:
                                                                                      "pa-1",
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                        height:
                                                                                          "100%",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "VerticalBrandTile",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            width:
                                                                                              "100%",
                                                                                            height:
                                                                                              "100%",
                                                                                            brand:
                                                                                              brand,
                                                                                            showGoodsServices: true,
                                                                                            canAddToBookmarks: true,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                            proxy: true,
                                                                          }
                                                                        }
                                                                      ),
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                            _c(
                                                              "VerticalBrandTileGrid",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.activeConflictedBrandsView ===
                                                                      "vertical-view",
                                                                    expression:
                                                                      "activeConflictedBrandsView === 'vertical-view'",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  brands:
                                                                    _vm.conflictedBrands,
                                                                  showGoodsServices: true,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-row justify-center align-center",
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  color:
                                                                    "tertiary",
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fa-light fa-thumbs-up"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "tertiary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "monitorBrand.conflictedBrands.noBrandFound"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.$vuetify.breakpoint.lgAndUp
                                          ? _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "section-title decorative-border-left",
                                                      },
                                                      [
                                                        _c("h2", [
                                                          _vm._v(
                                                            "Akzeptierte Marken"
                                                          ),
                                                        ]),
                                                        _c("h3", [
                                                          _vm._v(
                                                            "Folgende Marken haben Sie bereits beurteilt:"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-toolbar",
                                                      {
                                                        staticClass: "px-0",
                                                        attrs: {
                                                          dense: "",
                                                          light: "",
                                                          flat: "",
                                                          "max-height": "48",
                                                        },
                                                      },
                                                      [
                                                        _vm.$vuetify.breakpoint
                                                          .lgAndUp
                                                          ? _c("v-spacer")
                                                          : _vm._e(),
                                                        _vm.$vuetify.breakpoint
                                                          .mdAndUp
                                                          ? _c(
                                                              "v-btn-toggle",
                                                              {
                                                                attrs: {
                                                                  mandatory: "",
                                                                  dense: "",
                                                                  group: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.activeIgnoredBrandsView,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.activeIgnoredBrandsView =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "activeIgnoredBrandsView",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "carousel-view",
                                                                      text: "",
                                                                      disabled:
                                                                        _vm
                                                                          .ignoredBrands
                                                                          .length ===
                                                                        0,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "fa-light fa-rectangle-vertical-history"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "vertical-view",
                                                                      text: "",
                                                                      disabled:
                                                                        _vm
                                                                          .ignoredBrands
                                                                          .length ===
                                                                        0,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "fa-light fa-grid"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.ignoredBrands.length > 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column pb-2",
                                                          },
                                                          [
                                                            _c(
                                                              "Carousel3dSlider",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.activeIgnoredBrandsView ===
                                                                      "carousel-view",
                                                                    expression:
                                                                      "activeIgnoredBrandsView === 'carousel-view'",
                                                                  },
                                                                ],
                                                                key: _vm.brandQueryResultKey,
                                                                attrs: {
                                                                  loop: true,
                                                                  width: 400,
                                                                  height: 800,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      _vm._l(
                                                                        _vm.ignoredBrands,
                                                                        function (
                                                                          brand,
                                                                          index
                                                                        ) {
                                                                          return {
                                                                            key:
                                                                              "slide-" +
                                                                              index,
                                                                            fn: function () {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    key: index,
                                                                                    staticClass:
                                                                                      "pa-1",
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                        height:
                                                                                          "100%",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "VerticalBrandTile",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            width:
                                                                                              "100%",
                                                                                            height:
                                                                                              "100%",
                                                                                            brand:
                                                                                              brand,
                                                                                            showGoodsServices: false,
                                                                                            canAddToBookmarks: true,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                            proxy: true,
                                                                          }
                                                                        }
                                                                      ),
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                            _c(
                                                              "VerticalBrandTileGrid",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.activeIgnoredBrandsView ===
                                                                      "vertical-view",
                                                                    expression:
                                                                      "activeIgnoredBrandsView === 'vertical-view'",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  brands:
                                                                    _vm.ignoredBrands,
                                                                  showGoodsServices: false,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-row justify-center align-center",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "tertiary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "monitorBrand.ignoredBrands.noBrandFound"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Popup",
                                      {
                                        attrs: {
                                          title: "Aktuelle Konflikte",
                                          icon: _vm.$braendz.getBrandTypeIcon(
                                            "Combined"
                                          ),
                                        },
                                        model: {
                                          value: _vm.showConflictedBrandsPopup,
                                          callback: function ($$v) {
                                            _vm.showConflictedBrandsPopup = $$v
                                          },
                                          expression:
                                            "showConflictedBrandsPopup",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-container",
                                              { attrs: { fluid: "" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c("v-col", [
                                                      _vm.ignoredBrands.length >
                                                      0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pb-2",
                                                            },
                                                            [
                                                              _c(
                                                                "VerticalBrandTileGrid",
                                                                {
                                                                  attrs: {
                                                                    brands:
                                                                      _vm.ignoredBrands,
                                                                    showGoodsServices: false,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fill-height",
                                                              staticStyle: {
                                                                "padding-top":
                                                                  "100px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-center",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-right":
                                                                            "16px",
                                                                          "font-weight":
                                                                            "900",
                                                                        },
                                                                      attrs: {
                                                                        color:
                                                                          "tertiary",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " fa-light fa-face-thinking "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "tertiary--text",
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "16px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "monitorBrand.ignoredBrands.noBrandFound"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Popup",
                                      {
                                        attrs: {
                                          title: "Suchkonfiguration",
                                          icon: "fa-light fa-gears",
                                          "max-width": 800,
                                        },
                                        on: {
                                          opened:
                                            _vm.resetBrandQueryConfiguration,
                                        },
                                        model: {
                                          value:
                                            _vm.showBrandQueryConfigurationPopup,
                                          callback: function ($$v) {
                                            _vm.showBrandQueryConfigurationPopup =
                                              $$v
                                          },
                                          expression:
                                            "showBrandQueryConfigurationPopup",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card-text",
                                          { staticClass: "pa-5" },
                                          [
                                            _c(
                                              "table",
                                              { staticClass: "config-table" },
                                              [
                                                _c("tr", [
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-label", [
                                                        _vm._v(
                                                          "Nur neue Marken"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-switch", {
                                                        staticClass: "pa-0",
                                                        staticStyle: {
                                                          margin: "0px 7px",
                                                        },
                                                        attrs: {
                                                          inset: "",
                                                          dense: "",
                                                          flat: "",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.searchOnlyNewBrandsConfig,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.searchOnlyNewBrandsConfig =
                                                              $$v
                                                          },
                                                          expression:
                                                            "searchOnlyNewBrandsConfig",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _c("tr", [
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-label", [
                                                        _vm._v(
                                                          "Namensähnlichkeit"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-slider", {
                                                        attrs: {
                                                          dense: "",
                                                          "thumb-label": "",
                                                          min: 0,
                                                          max: 1,
                                                          step: 0.01,
                                                          "hide-details": "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "thumb-label",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var value =
                                                                  ref.value
                                                                return [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$n(
                                                                          value,
                                                                          "percent"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          3536800171
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.minNameSimilarityScoreConfig,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.minNameSimilarityScoreConfig =
                                                              $$v
                                                          },
                                                          expression:
                                                            "minNameSimilarityScoreConfig",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _c("tr", [
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-label", [
                                                        _vm._v(
                                                          "Logoähnlichkeit"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-slider", {
                                                        attrs: {
                                                          dense: "",
                                                          "thumb-label": "",
                                                          min: 0,
                                                          max: 1,
                                                          step: 0.01,
                                                          "hide-details": "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "thumb-label",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var value =
                                                                  ref.value
                                                                return [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$n(
                                                                          value,
                                                                          "percent"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          3536800171
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.minLogoSimilarityScoreConfig,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.minLogoSimilarityScoreConfig =
                                                              $$v
                                                          },
                                                          expression:
                                                            "minLogoSimilarityScoreConfig",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _c("tr", [
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-label", [
                                                        _vm._v(
                                                          "Maximum Results"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-select", {
                                                        staticClass: "pa-0",
                                                        staticStyle: {
                                                          margin: "0px 7px",
                                                        },
                                                        attrs: {
                                                          items:
                                                            _vm.limitOptions,
                                                          "return-object": "",
                                                          "persistent-hint": "",
                                                          "hide-details": "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "item",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "configureStoredBrandQueryPopup.numberOfResults",
                                                                          {
                                                                            number:
                                                                              item,
                                                                          }
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "selection",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "configureStoredBrandQueryPopup.numberOfResults",
                                                                          {
                                                                            number:
                                                                              item,
                                                                          }
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          3811055676
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.limitConfig,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.limitConfig =
                                                              $$v
                                                          },
                                                          expression:
                                                            "limitConfig",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { plain: "" },
                                                on: {
                                                  click: function () {
                                                    _vm.setBrandQueryConfiguration()
                                                    _vm.showBrandQueryConfigurationPopup = false
                                                  },
                                                },
                                              },
                                              [_vm._v("Übernehmen")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: 4 } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "mb-2",
                                        attrs: {
                                          column: "",
                                          "align-center": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: {
                                              elevation: "3",
                                              rounded: "",
                                              width: "100%",
                                            },
                                          },
                                          [
                                            _c("v-card-title", [
                                              _c("span", [
                                                _vm._v("Überwachung speichern"),
                                              ]),
                                            ]),
                                            _c("v-divider", {
                                              staticClass: "mx-3",
                                            }),
                                            _c(
                                              "v-form",
                                              {
                                                ref: "saveQueryForm",
                                                attrs: { light: "" },
                                                model: {
                                                  value: _vm.isSaveQueryValid,
                                                  callback: function ($$v) {
                                                    _vm.isSaveQueryValid = $$v
                                                  },
                                                  expression:
                                                    "isSaveQueryValid",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        outlined: "",
                                                        "hide-details": "",
                                                        label:
                                                          "Name der Überwachung",
                                                        required: "",
                                                        rules: [
                                                          _vm.$validation
                                                            .required,
                                                        ],
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.savedQueryName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.savedQueryName =
                                                            $$v
                                                        },
                                                        expression:
                                                          "savedQueryName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c("v-textarea", {
                                                      attrs: {
                                                        outlined: "",
                                                        "hide-details": "",
                                                        "auto-grow": true,
                                                        rows: 3,
                                                        label: _vm.$t(
                                                          "configureStoredBrandQueryPopup.description"
                                                        ),
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.savedQueryDescription,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.savedQueryDescription =
                                                            $$v
                                                        },
                                                        expression:
                                                          "savedQueryDescription",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("v-card-title", [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Automatisierung festlegen"
                                                    ),
                                                  ]),
                                                ]),
                                                _c("v-divider", {
                                                  staticClass: "mx-3",
                                                }),
                                                _c("v-card-text", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn-toggle",
                                                        {
                                                          staticClass: "ml-n1",
                                                          attrs: {
                                                            tile: "",
                                                            group: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.savedQueryAgentExecutionFrequency,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.savedQueryAgentExecutionFrequency =
                                                                $$v
                                                            },
                                                            expression:
                                                              "savedQueryAgentExecutionFrequency",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                value: null,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "configureStoredBrandQueryPopup.agentExecutionFrequencyNoneButton"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                value: "Daily",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "configureStoredBrandQueryPopup.agentExecutionFrequencyDailyButton"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                value: "Weekly",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "configureStoredBrandQueryPopup.agentExecutionFrequencyWeeklyButton"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "Monthly",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "configureStoredBrandQueryPopup.agentExecutionFrequencyMonthlyButton"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      !_vm.savedQueryAgentExecutionFrequency
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "pt-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "configureStoredBrandQueryPopup.agentExecutionFrequencyNoneDescription"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.savedQueryAgentExecutionFrequency ===
                                                      "Daily"
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "pt-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "configureStoredBrandQueryPopup.agentExecutionFrequencyDailyDescription"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.savedQueryAgentExecutionFrequency ===
                                                      "Weekly"
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "pt-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "configureStoredBrandQueryPopup.agentExecutionFrequencyWeeklyDescription"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.savedQueryAgentExecutionFrequency ===
                                                      "Monthly"
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "pt-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "configureStoredBrandQueryPopup.agentExecutionFrequencyMonthlyDescription"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label:
                                                          "Mailadresse (An)",
                                                        outlined: "",
                                                        readonly: "",
                                                        "hide-details": "",
                                                        required: "",
                                                        rules: [
                                                          _vm.$validation
                                                            .required,
                                                        ],
                                                        disabled:
                                                          !_vm.savedQueryAgentExecutionFrequency,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.savedQueryOwnerMailAddress,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.savedQueryOwnerMailAddress =
                                                            $$v
                                                        },
                                                        expression:
                                                          "savedQueryOwnerMailAddress",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c("v-combobox", {
                                                      attrs: {
                                                        outlined: "",
                                                        "hide-details": "",
                                                        rules:
                                                          _vm.savedQueryAgentExecutionFrequency
                                                            ? [
                                                                _vm.mailAddressesValid,
                                                              ]
                                                            : [],
                                                        items:
                                                          _vm.savedQueryAdditionalMailAddresses,
                                                        disabled:
                                                          !_vm.savedQueryAgentExecutionFrequency,
                                                        delimiters: [
                                                          " ",
                                                          ";",
                                                          ",",
                                                        ],
                                                        chips: "",
                                                        multiple: "",
                                                        label:
                                                          "Mailadressen (Cc)",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "selection",
                                                            fn: function (ref) {
                                                              var attrs =
                                                                ref.attrs
                                                              var item =
                                                                ref.item
                                                              var select =
                                                                ref.select
                                                              var selected =
                                                                ref.selected
                                                              return [
                                                                _c(
                                                                  "v-chip",
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        "input-value":
                                                                          selected,
                                                                        close:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          select,
                                                                        "click:close":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeMailAddress(
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    "v-chip",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        774992715
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.savedQuerySelectedAdditionalMailAddresses,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.savedQuerySelectedAdditionalMailAddresses =
                                                            $$v
                                                        },
                                                        expression:
                                                          "savedQuerySelectedAdditionalMailAddresses",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        items: _vm.locales,
                                                        outlined: "",
                                                        "hide-details": "",
                                                        label: "Sprache",
                                                        "persistent-hint": "",
                                                        "return-object": "",
                                                        disabled:
                                                          !_vm.savedQueryAgentExecutionFrequency,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "item",
                                                            fn: function (ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        item.text
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "selection",
                                                            fn: function (ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        item.text
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2727340572
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.savedQuerySelectedNotificationLanguage,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.savedQuerySelectedNotificationLanguage =
                                                            $$v
                                                        },
                                                        expression:
                                                          "savedQuerySelectedNotificationLanguage",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-actions",
                                              {
                                                staticClass:
                                                  "mt-10 d-flex justify-space-between",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      light: "",
                                                      color: "tertiary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setStep(3)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-chevron-left"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "monitorBrand.wizard.buttonBack"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      light: "",
                                                      color: "tertiary",
                                                      disabled:
                                                        !_vm.isSaveQueryValid,
                                                      loading:
                                                        _vm.storedBrandQuery
                                                          .isBusy,
                                                    },
                                                    on: {
                                                      click: _vm.saveQuery,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "monitorBrand.saveAndAutomate.buttonSave"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex justify-center",
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "section-title decorative-border-bottom text-center",
                          },
                          [
                            _c("h1", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("monitorBrand.missingFeature.title")
                                )
                              ),
                            ]),
                            _c("h3", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("monitorBrand.missingFeature.subtitle")
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass:
                          "d-flex flex-column justify-center text-center",
                        attrs: { cols: "12" },
                      },
                      [
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "monitorBrand.missingFeature.subscribeText"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "v-btn",
                          { attrs: { plain: "", to: { name: "Pricing" } } },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("fa-light fa-cart-shopping"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "monitorBrand.missingFeature.buttonPricing"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
        _c("ChatAside", {
          attrs: {
            instance: _vm.chatInstance,
            position: _vm.$vuetify.breakpoint.lgAndUp ? "sticky" : "fixed",
            elevation: 1,
            "max-width": _vm.$vuetify.breakpoint.lgAndUp
              ? "25%"
              : _vm.$vuetify.breakpoint.md
              ? "50%"
              : "100%",
          },
          model: {
            value: _vm.chatOpened,
            callback: function ($$v) {
              _vm.chatOpened = $$v
            },
            expression: "chatOpened",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }