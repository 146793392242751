












































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BusyObject } from '@/models/Busy';
import { BrandQueriesService, BrandQuerySession, BrandQuery, BrandQueryResult, BrandQueryResultItem, MasterDataItem } from '@/api/braendz';
import { SearchableField, FilterableField, BrandQueryMode, BrandStateCategory, Comparator } from '@/models/Query';
import { blobToBase64Async } from '@/models/Blob';

import BrandNameCombobox from '@/components/Inputs/BrandNameCombobox.vue';
import SearchTextField from '@/components/Inputs/SearchTextField.vue';
import ImagePicker from '@/components/ImagePicker.vue';
import TruncateTooltip from '@/components/TruncateTooltip.vue';
import BrandList from '@/components/Brands/BrandList.vue';
import FeatureBadge from '@/components/Features/FeatureBadge.vue';

@Component({
    model: {
        prop: "model",
        event: "modelChanged"
    },
    components: {
        BrandNameCombobox,
        SearchTextField,
        ImagePicker,
        TruncateTooltip,
        BrandList,
        FeatureBadge
    },
})
export default class BrandPicker extends Vue {
    // Fields
    public searchMode: 'Name' | 'Logo' | 'NameAndLogo' | 'RegistrationNumber' | 'Owner' = 'Name';
    public searchInputBrandName = "";
    public exactSearchEnabled = true;
    public searchInputRegistrationNumber = "";
    public searchInputLogo: Blob | null = null;
    public searchInputOwner = "";

    public brandQueryResult = new BusyObject<BrandQueryResult>();
    public brandQueryResultPageSize = 20;
    public brandQueryResultPage = 0;

    // Component Properties
    @Prop({ required: true })
    public model!: BrandQueryResultItem | null;

    // Getter and Setter
    public get selectedBrand(): BrandQueryResultItem | null {
        return this.model;
    }

    public set selectedBrand(value: BrandQueryResultItem | null) {
        this.$emit('modelChanged', value);
    }

    public get searchInputComplete(): boolean {
        switch(this.searchMode) {
        case 'Name':
            return !!this.searchInputBrandName;
        case 'Logo':
            return !!this.searchInputLogo;
        case 'NameAndLogo':
            return !!this.searchInputBrandName && !!this.searchInputLogo;
        case 'RegistrationNumber':
            return !!this.searchInputRegistrationNumber;
        case 'Owner':
            return !!this.searchInputOwner;
        default:
            return false;
        }
    }

    // Methods
    public async search(): Promise<void> {
        
        // Do not execute a search if the user input is not complete
        if(!this.searchInputComplete) {
            return;
        }

        const brandQuery: BrandQuery = {
            terms: [],
            size: this.brandQueryResultPageSize,
            skip: this.brandQueryResultPageSize * this.brandQueryResultPage,
            filters: [],
            scoring: { enabled: false }
        };

        if(this.searchMode === 'RegistrationNumber') {
            brandQuery.mode = BrandQueryMode.Exact;
            brandQuery.terms?.push({ field: SearchableField.RegistrationNumber, value: this.searchInputRegistrationNumber});
            brandQuery.terms?.push({ field: SearchableField.ApplicationNumber, value: this.searchInputRegistrationNumber});
        }
        else if(this.searchMode === 'Owner') {
            brandQuery.mode = BrandQueryMode.Default;
            brandQuery.terms?.push({ field: SearchableField.OwnerAddress, value: this.searchInputOwner});
        }
        else if(this.searchMode === 'Name') {
            brandQuery.mode = this.exactSearchEnabled ? BrandQueryMode.Exact : BrandQueryMode.Default;
            brandQuery.terms?.push({ field: SearchableField.Name, value: this.searchInputBrandName});
        }
        else {
            // Default: Brand name and/or Logo
            brandQuery.mode = BrandQueryMode.Default;

            if((this.searchMode === 'NameAndLogo') && this.searchInputBrandName) {
                // Add a term for the Brand-Name field:
                brandQuery.terms?.push({ field: SearchableField.Name, value: this.searchInputBrandName});

                // Add a term for the logo text field
                brandQuery.terms?.push({ field: SearchableField.BrandLogoText, value: this.searchInputBrandName});
            }

            if((this.searchMode === 'NameAndLogo' || this.searchMode === 'Logo') && this.searchInputLogo) {
                // Add the logo
                brandQuery.logo = await blobToBase64Async(this.searchInputLogo);
            }
        }

        // Configure Filters:
        brandQuery.filters?.push({ field: FilterableField.BrandStateCategory, value: BrandStateCategory.New, comparator: Comparator.Equals });
        brandQuery.filters?.push({ field: FilterableField.BrandStateCategory, value: BrandStateCategory.Alive, comparator: Comparator.Equals });

        const brandQuerySession = {
            id: this.brandQueryResult.object?.sessionId,
            query: brandQuery,
        } as BrandQuerySession;

        // Execute Search:
        await this.brandQueryResult.create(async () => {
            return await BrandQueriesService.executeBrandQuery(brandQuerySession);
        });
    }
}
