

















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { Flicking, FlickingOptions } from "@egjs/vue-flicking";
import { Perspective } from "@egjs/flicking-plugins";

// Import Flicking CSS
import '@egjs/vue-flicking/dist/flicking.css';

@Component({
    components: {
        Flicking
    }
})
export default class FlickingSlider extends Vue {
    // Component Properties

    /**
     * Align position of the panels within viewport.
     */
    @Prop({ required: false, type: String, default: 'center' }) 
    public align!: 'prev' | 'center' | 'next';

    /**
     * Index of the panel to move when Flicking's init is called.
     */
     @Prop({ required: false, type: Number, default: 0 }) 
    public defaultIndex!: number;

    /**
     * Enables circular(continuous loop) mode, which connects first/last panel for continuous scrolling.
     */
    @Prop({ required: false, type: Boolean, default: false }) 
    public circular!: boolean;

    /**
     * Prevent the view(camera element) from going out of the first/last panel, so it won't show empty spaces before/after the first/last panel.
     * Only can be enabled when circular is false.
     */
    @Prop({ required: false, type: Boolean, default: false }) 
    public bound!: boolean;

    /**
     * Update height of the viewport element after movement same to the height of the panel below.
     */
    @Prop({ required: false, type: Boolean, default: false }) 
    public adaptive!: boolean;
    

    /**
     * A visible number of panels on viewport. Enabling this option will automatically resize panel size.
     */
    @Prop({ required: false, type: Number, default: -1 }) 
    public panelsPerView!: number;

    /**
     * Enabling this option will not change width/height style of the panels if Flicking#panelsPerView is enabled.
     * This behavior can be useful in terms of performance when you're manually managing all panel sizes.
     */
    @Prop({ required: false, type: Boolean, default: false }) 
    public noPanelStyleOverride!: boolean;

    /**
     * Enabling this option will automatically call Flicking#resize when all image/video inside panels are loaded.
     * This can be useful when you have contents inside Flicking that changes its size when it's loaded.
     */
     @Prop({ required: false, type: Boolean, default: true }) 
    public resizeOnContentsReady!: boolean;

    /**
     * Deceleration value for panel movement animation which is triggered by user input. A higher value means a shorter animation time.
     */
     @Prop({ required: false, type: Number, default: 0.0075 }) 
    public deceleration!: number;

    /**
     * Default duration of the animation (ms).
     */
     @Prop({ required: false, type: Number, default: 500 }) 
    public duration!: number;

    /**
     * Whether to automatically call resize() when the viewport element(.flicking-viewport)'s size is changed.
     * Change window size or change orientation(on mobile) to see its effect.
     */
     @Prop({ required: false, type: Boolean, default: true }) 
    public autoResize!: boolean;


    /**
     * Default duration of the animation (ms).
     */
     @Prop({ required: false, type: Number, default: 1 }) 
    public rotate!: number;

    /**
     * Default duration of the animation (ms).
     */
     @Prop({ required: false, type: Number, default: 1000 }) 
    public perspective!: number;

    /**
     * Default duration of the animation (ms).
     */
     @Prop({ required: false, type: Number, default: 1 }) 
    public scale!: number;

    // Getter and Setter
    public get perspectivePlugin(): Perspective {
        return new Perspective({
            rotate: this.rotate,
            perspective: this.perspective,
            scale: this.scale
        });
    }
    
    // Lifecycle Hooks
    
    // Methods

}
