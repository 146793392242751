












































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator';
import { AccountInfo } from "@azure/msal-browser";
import { Guid } from 'guid-typescript';

import { RobotOption, TwitterCardOption } from '@/models/MetaTags';
import { getLocalesString, Locale, findLocale, locales } from '@/models/Locale';
import { GoodOrService, MasterDataItem, BrandQueryResult, BrandQueriesService, BrandQuerySession, BrandQuery, BrandQueryResultItem, BrandIndexItem, StoredBrandQuery, StoredBrandQueriesService, User } from '@/api/braendz';
import { BusyList, BusyObject } from '@/models/Busy';
import { BrandStateCategory, Comparator, FilterableField, SearchableField } from '@/models/Query';

import BrandPicker from '@/components/Brands/BrandPicker.vue';
import ExplainButton from '@/components/Assistant/ExplainButton.vue';
import VerticalBrandTile from '@/components/Brands/VerticalBrandTile.vue';
import VerticalBrandTileGrid from '@/components/Brands/VerticalBrandTileGrid.vue';
import Label from '@/components/Label.vue';
import ChatAside from '@/components/Assistant/ChatAside.vue';
import ExplainConflictButton from '@/components/Assistant/ExplainConflictButton.vue';
import { blobToBase64Async } from '@/models/Blob';
import GaugeChart from '@/components/Statistics/GaugeChart.vue';
import RadialBar from '@/components/Statistics/RadialBar.vue';
import Popup from '@/components/Popups/Popup.vue';
import DashboardCard from '@/components/Dashboard/DashboardCard.vue';
import Counter from '@/components/Statistics/Counter.vue';
import ThumbsButtons from '@/components/Feedback/ThumbsButtons.vue';
import BrandList from '@/components/Brands/BrandList.vue';
import FlickingSlider from '@/components/Carousels/FlickingSlider.vue';
import Carousel3dSlider from '@/components/Carousels/Carousel3dSlider.vue';
import { AgentExecutionFrequency } from '@/models/AgentExecutionFrequency';

@Component({
  components: {
    BrandPicker,
    ExplainButton,
    ExplainConflictButton,
    VerticalBrandTile,
    VerticalBrandTileGrid,
    ChatAside,
    Label,
    GaugeChart,
    RadialBar,
    Popup,
    DashboardCard,
    Counter,
    ThumbsButtons,
    BrandList,
    FlickingSlider,
    Carousel3dSlider
  },
  metaInfo() {
    return {
      title: this.$i18n.t("monitorBrand.title").toString(),
      meta: [
        { name: 'robots', content: [RobotOption.NoIndex, RobotOption.NoFollow].join(',') },

        // Open Graph: Facebook, Instagram, WhatsApp, LinkedIn, Xing, Twitter:
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.$i18n.t("monitorBrand.metaTags.title").toString() },
        { property: 'og:description', content: this.$i18n.t("monitorBrand.metaTags.description").toString() },
        { property: 'og:image', content: `${window.location.origin}${require('@/assets/logos/braendz-logo-tm-blue.png')}` },
        { property: 'og:locale', content: this.$i18n.locale },
        { property: 'og:locale:alternate', content: getLocalesString(',') },
        { property: 'og:site_name', content: this.$i18n.t("metaTags.title").toString()},

        // Twitter:
        { property: 'twitter:card', content: TwitterCardOption.SummaryLargeImage },
        { property: 'twitter:site', content: `@${process.env.VUE_APP_TWITTER_ACCOUNT}` }
      ]
    };
  }
})
export default class Monitor extends Vue {   
    // Private Members:
    private m_currentAssessBrandIndex = 0;
    private m_savedQueryOwnerMailAddress: string | null = null;

    // Public Fields:
    public chatOpened = false;
    public chatInstance = "MonitorAssistant";

    public step = 1;

    public selectedBrand: BrandQueryResultItem | null = null;
    public additionalRegistrationOfficeCodes: MasterDataItem[] = [];

    public excludedBrands: BrandIndexItem[] = [];
    public ignoredBrands: BrandIndexItem[] = [];
    public monitoredBrands: BrandIndexItem[] = [];
    public limit = 25;
    public searchOnlyNewBrands = true;
    public searchOnlyNewBrandsConfig = this.searchOnlyNewBrands;
    public minNameSimilarityScore = 0.7;
    public minNameSimilarityScoreConfig = this.minNameSimilarityScore;
    public minLogoSimilarityScore = 0.9;
    public minLogoSimilarityScoreConfig = this.minLogoSimilarityScore;
    public limitConfig = this.limit;
    public limitOptions = [ 25, 50, 75, 100 ];

    public brandQueryResult = new BusyObject<BrandQueryResult>();
    public brandQueryResultKey: string = Guid.EMPTY;
    public showBrandQueryConfigurationPopup = false;
    public showConflictedBrandsPopup = false;

    public activeConflictedBrandsView: 'carousel-view' | 'vertical-view' = this.$vuetify.breakpoint.mdAndUp ? 'carousel-view' : 'vertical-view';
    public activeExcludedBrandsView: 'carousel-view' | 'vertical-view' = this.$vuetify.breakpoint.mdAndUp ? 'carousel-view' : 'vertical-view';
    public activeIgnoredBrandsView: 'carousel-view' | 'vertical-view' = this.$vuetify.breakpoint.mdAndUp ? 'carousel-view' : 'vertical-view';

    public riskColors = [
        { color: '#269926', limit: 9.09 },
        { color: '#59AB20', limit: 18.18 },
        { color: '#8BBF1B', limit: 27.27 },
        { color: '#BDD317', limit: 36.36 },
        { color: '#E0E611', limit: 45.45 },
        { color: '#FFC107', limit: 54.54 },
        { color: '#F29E27', limit: 63.63 },
        { color: '#E67C37', limit: 72.72 },
        { color: '#D95940', limit: 81.81 },
        { color: '#CE3440', limit: 90.90 },
        { color: '#DB0000', limit: 100 }
    ];

    public isSaveQueryValid = false;
    public storedBrandQuery = new BusyObject<StoredBrandQuery>();

    public savedQueryName = "";
    public savedQueryDescription: string | null = null;
    public savedQueryAgentExecutionFrequency: AgentExecutionFrequency | null = null;
    public savedQuerySelectedAdditionalMailAddresses: string[] = [];
    public savedQueryAdditionalMailAddresses: string[] = [];
    public savedQuerySelectedNotificationLanguage: Locale | null = findLocale(this.$i18n.locale) ?? findLocale('en-GB') ?? locales[0];

    // Getter:
    public get userAccount(): AccountInfo | null {
        return this.$store.state.userAccount;
    }

    public get completedStep(): number {
        if(!this.selectedBrand) {
            return 0;
        }
        else {
            return 4;
        }
    }

    public get registrationOfficeCodes(): BusyList<MasterDataItem> {
        return this.$store.state.registrationOfficeCodes as BusyList<MasterDataItem>;
    }

    public get currentAssessBrandIndex(): number {
        return this.m_currentAssessBrandIndex;
    }
    public set currentAssessBrandIndex(value: number) {
        if(this.brandQueryResult.object?.items && value >= 0 && value < this.brandQueryResult.object.items.length) {
            this.m_currentAssessBrandIndex = value;
        }
        else {
            this.m_currentAssessBrandIndex = 0;
        }
    }

    public get currentAssessBrand(): BrandQueryResultItem | null {
        if(!this.brandQueryResult.object?.items) {
            return null;
        }

        if(this.currentAssessBrandIndex >= 0 && this.brandQueryResult.object.items.length > this.currentAssessBrandIndex) {
            return this.brandQueryResult.object.items[this.currentAssessBrandIndex];
        }
            
        return null;
    }

    public get conflictedBrands() : BrandQueryResultItem[] | null {
        if(!this.brandQueryResult.object?.items) {
            return null;
        }

        return this.brandQueryResult.object.items.filter(b => 
            !b?.indexItem?.id 
            || (!this.excludedBrands.find(e => e.id === b.indexItem?.id) 
                && !this.ignoredBrands.find(e => e.id === b.indexItem?.id)));
    }

    public get includedRegistrationOfficeCodes(): MasterDataItem[] {
        const result = [] as MasterDataItem[];

        if(!this.selectedBrand?.indexItem?.registrationOfficeCode) {
            return result;
        }

        // Add the office of the selected brand:
        const baseOffice = this.selectedBrand.indexItem.registrationOfficeCode;
        result.push(baseOffice);

        // Find and add all related offices:
        this.$braendz.registrationOfficeCodeRelationships.find(o => o.key === baseOffice.key)?.related.forEach(r => {
            const related = this.registrationOfficeCodes.list.find(o => o.key === r) ?? { key: r } as MasterDataItem;
            result.push(related);
        });

        return result;
    }

    public get locales(): Locale[] {
        return locales;
    }

    public get userMailAddress(): string | null {
        return (this.$store.state.user as BusyObject<User>)?.object?.mailAddress ?? null;
    }

    public get savedQueryOwnerMailAddress(): string | null {
        if(this.m_savedQueryOwnerMailAddress) {
            return this.m_savedQueryOwnerMailAddress;
        }

        if(this.storedBrandQuery.object?.ownerMailAddress) {
            return this.storedBrandQuery.object.ownerMailAddress;
        }

        return this.userMailAddress;
    }

    // Watchers & Event Handlers:
  
    // Component Lifecycle
    public mounted(): void {
        // Initialize some stuff here:
        this.$store.dispatch("updateMasterData");
    }

    // Methods
    public setStep(step: number): void {
        if(this.step < 2 && step === 2) {
            this.updateBrandQueryResult();
        }
        if(this.step < 3 && step === 3) {
            this.updateBrandQueryResult();
        }
        this.step = step;
    }

    public jumpToStep(step: number): void {
        if(this.step < 2 && step === 2) {
            this.updateBrandQueryResult();
        }
        if(this.step < 3 && step === 3) {
            this.updateBrandQueryResult();
        }
    }

    public excludeBrand(brand: BrandQueryResultItem | BrandIndexItem | null | undefined) {
        if(!brand) return;

        const indexItem = "indexItem" in brand ? brand.indexItem : brand as BrandIndexItem;

        if(!indexItem?.id) return;

        // Remove brand from ignored list if available:
        let index = this.ignoredBrands.findIndex(e => e.id && e.id === indexItem.id);
        if(index && index > -1) this.ignoredBrands.splice(index, 1);

        // Remove brand from monitor list if available:
        index = this.monitoredBrands.findIndex(e => e.id && e.id === indexItem.id);
        if(index && index > -1) this.monitoredBrands.splice(index, 1);

        // Add brand to the list of excluded items:
        if(!this.excludedBrands.find(e => e.id === indexItem.id)) {
            this.excludedBrands.push(indexItem);
        }
    }

    public ignoreBrand(brand: BrandQueryResultItem | BrandIndexItem | null | undefined) {
        if(!brand) return;

        const indexItem = "indexItem" in brand ? brand.indexItem : brand as BrandIndexItem;

        if(!indexItem?.id) return;

        // Remove brand from excluded list if available:
        let index = this.excludedBrands.findIndex(e => e.id && e.id === indexItem.id);
        if(index && index > -1) this.excludedBrands.splice(index, 1);

        // Remove brand from monitor list if available:
        index = this.monitoredBrands.findIndex(e => e.id && e.id === indexItem.id);
        if(index && index > -1) this.monitoredBrands.splice(index, 1);

        // Add brand to the list of excluded items:
        if(!this.ignoredBrands.find(e => e.id === indexItem.id)) {
            this.ignoredBrands.push(indexItem);
        }
    }

    public monitorBrand(brand: BrandQueryResultItem | BrandIndexItem | null | undefined) {
        if(!brand) return;

        const indexItem = "indexItem" in brand ? brand.indexItem : brand as BrandIndexItem;

        if(!indexItem?.id) return;

        // Remove brand from excluded list if available:
        let index = this.excludedBrands.findIndex(e => e.id && e.id === indexItem.id);
        if(index && index > -1) this.excludedBrands.splice(index, 1);

        // Remove brand from ignored list if available:
        index = this.ignoredBrands.findIndex(e => e.id && e.id === indexItem.id);
        if(index && index > -1) this.ignoredBrands.splice(index, 1);

        // Add brand to the list of excluded items:
        if(!this.monitoredBrands.find(e => e.id === indexItem.id)) {
            this.monitoredBrands.push(indexItem);
        }
    }

    public assessNext() {
        this.currentAssessBrandIndex++;
    }

    public assessmentStatus(brand: BrandQueryResultItem | BrandIndexItem | null | undefined): 'Excluded' | 'Ignored' | 'Monitored' | null {
        if(!brand) return null;

        const indexItem = "indexItem" in brand ? brand.indexItem : brand as BrandIndexItem;

        if(!indexItem?.id) return null;

        if(this.excludedBrands.find(e => e.id && e.id === indexItem.id)) return 'Excluded';
        if(this.ignoredBrands.find(e => e.id && e.id === indexItem.id)) return 'Ignored';
        if(this.monitoredBrands.find(e => e.id && e.id === indexItem.id)) return 'Monitored';

        return null;
    }

    public resetBrandQueryConfiguration() {
        this.searchOnlyNewBrandsConfig = this.searchOnlyNewBrands;
        this.minNameSimilarityScoreConfig = this.minNameSimilarityScore;
        this.minLogoSimilarityScoreConfig = this.minLogoSimilarityScore;
        this.limitConfig = this.limit;
    }

    public setBrandQueryConfiguration(): void {
        this.searchOnlyNewBrands = this.searchOnlyNewBrandsConfig;
        this.minNameSimilarityScore = this.minNameSimilarityScoreConfig;
        this.minLogoSimilarityScore = this.minLogoSimilarityScoreConfig;
        this.limit = this.limitConfig;
        this.updateBrandQueryResult();
    }

    public async createBrandQuery(): Promise<BrandQuery | null> {
        if(!this.selectedBrand || !this.selectedBrand.indexItem) return null;

        const result = {
            limit: this.limit,
            terms: [],
            filters: [],
            facets: []
        } as BrandQuery;

        // Configure scoring:
        result.scoring = { 
            enabled: true, 
            brandId: this.selectedBrand.indexItem.brandId,
            minNameSimilarityScore: this.minNameSimilarityScore,
            minLogoSimilarityScore: this.minLogoSimilarityScore,
        };

        // Configure terms:
        if(this.selectedBrand.indexItem.name) {
            result.terms?.push({ field: SearchableField.Name, value: this.selectedBrand.indexItem.name });
            result.terms?.push({ field: SearchableField.BrandLogoText, value: this.selectedBrand.indexItem.name });
        }
        else if(this.selectedBrand.indexItem.brandLogoText) {
            result.terms?.push({ field: SearchableField.Name, value: this.selectedBrand.indexItem.brandLogoText });
            result.terms?.push({ field: SearchableField.BrandLogoText, value: this.selectedBrand.indexItem.brandLogoText });
        }

        // Configure logo:
        const brandLogoUrl = this.$braendz.getBrandLogoUrl(this.selectedBrand.indexItem);
        if(brandLogoUrl) {
            // const binaryString = await BrandImagesService.getBrandImage(btoa(this.selectedBrand.indexItem.brandLogoUrl), true);
            const blob = await fetch(brandLogoUrl).then(r => r.blob());
            result.logo = await blobToBase64Async(blob);
        }

        // Configure filters:
        for(const registrationOfficeCode of this.includedRegistrationOfficeCodes) {
            result.filters?.push({ field: FilterableField.RegistrationOfficeCode, value: registrationOfficeCode.key, comparator: Comparator.Equals })
        }
        for(const registrationOfficeCode of this.additionalRegistrationOfficeCodes) {
            result.filters?.push({ field: FilterableField.RegistrationOfficeCode, value: registrationOfficeCode.key, comparator: Comparator.Equals })
        }

        // TODO: Clarify if we also need to add brandType-Filter.
        // Clarified: It is not necessary but maybe a nice to have for the future so that the user has the option to specify the brand type.

        if(this.selectedBrand.indexItem.niceClasses) {
            for(const niceClass of this.selectedBrand.indexItem.niceClasses) {
                result.filters?.push({ field: FilterableField.NiceClasses, value: niceClass.key, comparator: Comparator.Equals })
            }
        }

        if(this.searchOnlyNewBrands) {
            result.filters?.push({ field: FilterableField.BrandStateCategory, value: BrandStateCategory.New, comparator: Comparator.Equals })
        }
        else {
            result.filters?.push({ field: FilterableField.BrandStateCategory, value: BrandStateCategory.New, comparator: Comparator.Equals })
            result.filters?.push({ field: FilterableField.BrandStateCategory, value: BrandStateCategory.Alive, comparator: Comparator.Equals })
        }

        // Configured excluded brands:
        if(this.selectedBrand.indexItem.id && !this.excludedBrands.find(e => e.id && e.id === this.selectedBrand?.indexItem?.id)) {
            // Add the selected brand to the excluded brands, if it is not yet in the list of excluded brands:
            result.excludedIds = [ this.selectedBrand.indexItem.id ];
        }
        for(const excludedBrand of this.excludedBrands) {
            if(excludedBrand.id) {
                result.excludedIds?.push(excludedBrand.id);
            }
        }

        // Configured no-risk brands:
        if(this.ignoredBrands.length > 0) {
            result.scoring.excludedIds = [];
            for(const noRiskBrand of this.ignoredBrands) {
                if(noRiskBrand?.id) {
                    result.scoring.excludedIds.push(noRiskBrand.id);
                }
            }
        }

        return result;
    }

    public async updateBrandQueryResult(): Promise<void> {
        await this.brandQueryResult.create(async () => {

            const brandQuery = await this.createBrandQuery();

            if(!brandQuery) {
                return null;
            }

            const brandQuerySession = {
                id: this.brandQueryResult?.object?.sessionId ?? null, 
                query: brandQuery,
            } as BrandQuerySession;

            return await BrandQueriesService.executeBrandQuery(brandQuerySession);
        });

        this.brandQueryResultKey = Guid.create().toString();
    }

    public showConflicts(): void {
        if(this.$vuetify.breakpoint.lgAndUp) {
            this.$router.push({ path: '/monitor', hash: '#conflicts' });
        }
        else {
            this.showConflictedBrandsPopup = true;
        }
    }

    public async saveQuery(): Promise<void> {
         
        if (!((this.$refs.saveQueryForm as Vue & { validate: () => boolean }).validate())) {
            return;
        }
       
        await this.storedBrandQuery.create(async () => {

            if(!this.savedQueryOwnerMailAddress) {
                return this.storedBrandQuery.object;
            }

            const brandQuery = await this.createBrandQuery();

            if(!brandQuery) {
                return this.storedBrandQuery.object;
            }

            const storedBrandQuery = {
                id: this.storedBrandQuery.object?.id,
                settings: { 
                    query: brandQuery
                },
                name: this.savedQueryName,
                ownerMailAddress: this.savedQueryOwnerMailAddress,
                description: this.savedQueryDescription,
                notificationLanguage: this.savedQuerySelectedNotificationLanguage?.locale,
                agentExecutionFrequency: this.savedQueryAgentExecutionFrequency,
                additionalMailAddresses: this.savedQuerySelectedAdditionalMailAddresses
            } as StoredBrandQuery;

            return await StoredBrandQueriesService.upsertStoredBrandQueryCurrentUserDefaultAccount(storedBrandQuery);
        });
    }

    public removeMailAddress(item: string) {
        this.savedQuerySelectedAdditionalMailAddresses.splice(this.savedQuerySelectedAdditionalMailAddresses.indexOf(item), 1);
    }

    public mailAddressesValid(value: []): true | string {
        if(!this.savedQueryAgentExecutionFrequency) {
            return true;
        }

        if(!value) {
            return true;
        }
        
        for(const mail of value) {
            const validationResult = this.$validation.mail(mail as string, '');
            if(validationResult !== true) {
                return validationResult;
            }
        }
        
        return true;
    }
}
