var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _c(
        "v-scroll-y-transition",
        {
          staticClass: "d-flex flex-column",
          attrs: { group: "", "leave-absolute": "" },
        },
        [
          _c(
            "v-chip-group",
            {
              key: "searchInputMode",
              staticClass: "my-3 align-self-center",
              attrs: {
                mandatory: "",
                "active-class": "primary primary--text",
                "show-arrows": "",
              },
              model: {
                value: _vm.searchMode,
                callback: function ($$v) {
                  _vm.searchMode = $$v
                },
                expression: "searchMode",
              },
            },
            [
              _c(
                "v-chip",
                { attrs: { label: "", outlined: "", value: "Name" } },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("fa-light fa-input-text"),
                  ]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("brandSearch.searchModes.name"))),
                  ]),
                ],
                1
              ),
              !_vm.$store.getters.hasFeature("SEARCH_LOGO")
                ? _c(
                    "FeatureBadge",
                    {
                      staticStyle: { "z-index": "1" },
                      attrs: {
                        small: "",
                        overlap: "",
                        "offset-x": "20",
                        "offset-y": "13",
                        hasFeature: false,
                      },
                    },
                    [
                      _c(
                        "v-chip",
                        {
                          attrs: {
                            label: "",
                            outlined: "",
                            value: "Logo",
                            disabled: true,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("fa-light fa-image"),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("brandSearch.searchModes.logo"))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-badge",
                    {
                      staticStyle: { "z-index": "1" },
                      attrs: {
                        content: "Pre",
                        color: "green darken-3",
                        overlap: "",
                        "offset-x": "25",
                        "offset-y": "16",
                      },
                    },
                    [
                      _c(
                        "v-chip",
                        { attrs: { label: "", outlined: "", value: "Logo" } },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("fa-light fa-image"),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("brandSearch.searchModes.logo"))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              !_vm.$store.getters.hasFeature("SEARCH_LOGO")
                ? _c(
                    "FeatureBadge",
                    {
                      staticStyle: { "z-index": "1" },
                      attrs: {
                        small: "",
                        overlap: "",
                        "offset-x": "20",
                        "offset-y": "13",
                        hasFeature: false,
                      },
                    },
                    [
                      _c(
                        "v-chip",
                        {
                          attrs: {
                            label: "",
                            outlined: "",
                            value: "NameAndLogo",
                            disabled: true,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("$combined-mark"),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("brandSearch.searchModes.nameAndLogo")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-badge",
                    {
                      staticStyle: { "z-index": "1" },
                      attrs: {
                        content: "Pre",
                        color: "green darken-3",
                        overlap: "",
                        "offset-x": "25",
                        "offset-y": "16",
                      },
                    },
                    [
                      _c(
                        "v-chip",
                        {
                          attrs: {
                            label: "",
                            outlined: "",
                            value: "NameAndLogo",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("$combined-mark"),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("brandSearch.searchModes.nameAndLogo")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                "FeatureBadge",
                {
                  staticStyle: { "z-index": "1" },
                  attrs: {
                    small: "",
                    overlap: "",
                    "offset-x": "20",
                    "offset-y": "13",
                    hasFeature: _vm.$store.getters.hasFeature("SEARCH_OWNER"),
                  },
                },
                [
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        label: "",
                        outlined: "",
                        value: "Owner",
                        disabled:
                          !_vm.$store.getters.hasFeature("SEARCH_OWNER"),
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("fa-light fa-buildings"),
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("brandSearch.searchModes.owner"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "FeatureBadge",
                {
                  staticStyle: { "z-index": "1" },
                  attrs: {
                    small: "",
                    overlap: "",
                    "offset-x": "20",
                    "offset-y": "13",
                    hasFeature: _vm.$store.getters.hasFeature(
                      "SEARCH_APPREGNUMBER"
                    ),
                  },
                },
                [
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        label: "",
                        outlined: "",
                        value: "RegistrationNumber",
                        disabled: !_vm.$store.getters.hasFeature(
                          "SEARCH_APPREGNUMBER"
                        ),
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("fa-light fa-input-numeric"),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("brandSearch.searchModes.registrationNumber")
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.searchMode === "Name" || _vm.searchMode === "NameAndLogo"
            ? _c("BrandNameCombobox", {
                key: "searchInputBrandName",
                staticClass: "my-3",
                attrs: {
                  outlined: "",
                  label: _vm.$t("brandNameCombobox.label"),
                  icon: "fa-light fa-magnifying-glass",
                },
                on: { inputChanged: _vm.search },
                model: {
                  value: _vm.searchInputBrandName,
                  callback: function ($$v) {
                    _vm.searchInputBrandName = $$v
                  },
                  expression: "searchInputBrandName",
                },
              })
            : _vm._e(),
          _vm.searchMode === "Logo" || _vm.searchMode === "NameAndLogo"
            ? _c("ImagePicker", {
                key: "searchInputLogo",
                staticClass: "my-3",
                attrs: {
                  title: _vm.$t("brandSearch.logoPickerTitle"),
                  icon: "fa-light fa-image",
                  "max-preview-height": "300",
                },
                model: {
                  value: _vm.searchInputLogo,
                  callback: function ($$v) {
                    _vm.searchInputLogo = $$v
                  },
                  expression: "searchInputLogo",
                },
              })
            : _vm._e(),
          _vm.searchMode === "RegistrationNumber"
            ? _c("SearchTextField", {
                key: "searchInputRegistrationNumber",
                staticClass: "my-3",
                attrs: {
                  label: _vm.$t("registrationNumberTextField.label"),
                  outlined: "",
                  icon: "fa-light fa-magnifying-glass",
                },
                on: { confirmed: _vm.search },
                model: {
                  value: _vm.searchInputRegistrationNumber,
                  callback: function ($$v) {
                    _vm.searchInputRegistrationNumber = $$v
                  },
                  expression: "searchInputRegistrationNumber",
                },
              })
            : _vm._e(),
          _vm.searchMode === "Owner"
            ? _c("SearchTextField", {
                key: "searchInputOwner",
                staticClass: "my-3",
                attrs: {
                  label: _vm.$t("ownerTextField.label"),
                  outlined: "",
                  icon: "fa-light fa-magnifying-glass",
                },
                on: { confirmed: _vm.search },
                model: {
                  value: _vm.searchInputOwner,
                  callback: function ($$v) {
                    _vm.searchInputOwner = $$v
                  },
                  expression: "searchInputOwner",
                },
              })
            : _vm._e(),
          _c(
            "v-btn",
            {
              key: "searchButton",
              staticClass: "my-3 align-self-center",
              attrs: {
                text: "",
                width: "300",
                color: "primary",
                loading: _vm.brandQueryResult.isBusy,
                disabled: !_vm.searchInputComplete,
              },
              on: { click: _vm.search },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("fa-light fa-search"),
              ]),
              _vm._v(" " + _vm._s(_vm.$t("brandPicker.searchButton")) + " "),
            ],
            1
          ),
        ],
        1
      ),
      _vm.brandQueryResult.object &&
      _vm.brandQueryResult.object.items &&
      _vm.brandQueryResult.object.items.length > 0
        ? _c("BrandList", {
            attrs: {
              brands: _vm.brandQueryResult.object.items,
              grouped: "",
              selectionMode: "Single",
            },
            model: {
              value: _vm.selectedBrand,
              callback: function ($$v) {
                _vm.selectedBrand = $$v
              },
              expression: "selectedBrand",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }