








































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BrandQueryResultItem, MasterDataItem, BrandIndexItem, Brand } from '@/api/braendz';

import BrandListItem from '@/components/Brands/BrandListItem.vue';

@Component({
    model: {
        prop: "model",
        event: "modelChanged"
    },
    components: {
        BrandListItem
    },
})
export default class BrandList extends Vue {
    // Fields

    // Component Properties
    @Prop({ required: true })
    public brands!: (BrandQueryResultItem | BrandIndexItem | Brand | null | undefined)[];
    
    @Prop({ required: false })
    public model?: (BrandQueryResultItem | BrandIndexItem | Brand)[] | BrandQueryResultItem | BrandIndexItem | Brand;

    @Prop({ required: false, type: Boolean, default: true })
    public grouped!: boolean;

    @Prop({ required: false, default: "None" })
    public selectionMode!: "None" | "Single" | "Multiple";

    // Getter and Setter
    public get selection(): (BrandQueryResultItem | BrandIndexItem | Brand)[] | BrandQueryResultItem | BrandIndexItem | Brand | null | undefined {
        return this.model;
    }

    public set selection(value: (BrandQueryResultItem | BrandIndexItem | Brand)[] | BrandQueryResultItem | BrandIndexItem | Brand | null | undefined) {
        this.$emit('modelChanged', value);
    }

    public get groupedItems(): Map<MasterDataItem | null | undefined, (BrandQueryResultItem | BrandIndexItem | Brand | null | undefined)[]> {
        var grouped = this.brands.reduce((groups: Map<MasterDataItem | null | undefined, (BrandQueryResultItem | BrandIndexItem | Brand | null | undefined)[]>, item: BrandQueryResultItem | BrandIndexItem | Brand | null | undefined) => {
            
            let registrationOfficeCode: MasterDataItem | null | undefined;
            if(!item) registrationOfficeCode = undefined;
            else if("indexItem" in item) registrationOfficeCode = item.indexItem?.registrationOfficeCode;
            else if("registrationOfficeCode" in item) registrationOfficeCode = item?.registrationOfficeCode;
            else registrationOfficeCode = undefined;

            for(const group of groups.keys()) {
                if(group?.key === registrationOfficeCode?.key) {
                    groups.get(group)?.push(item ? item : undefined);
                    return groups;
                }
            }
            groups.set(registrationOfficeCode ? registrationOfficeCode : undefined, [item ? item : undefined]);
            return groups;
        }, new Map<MasterDataItem | null | undefined, (BrandQueryResultItem | BrandIndexItem | Brand | null | undefined)[]>());
        
        // Move the any Null-Item to the top:
        const groupedArray = Array.from(grouped.entries());
        var nullItemIndex = groupedArray.findIndex((entry) => !entry[0]);

        if(nullItemIndex >= 0) {
            const [nullItem] = groupedArray.splice(nullItemIndex, 1);
            groupedArray.splice(0, 0, nullItem);
        }
        return new Map(groupedArray);
    }
    
}
